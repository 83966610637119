import React from 'react';
import {Inline} from '@nib/layout';
import Link from '@nib-components/link';
import {Link as GatsbyLink} from 'gatsby';

const Banner = () => (
  <Inline space={2}>
    <span>Design Tokens for Mesh have just been released!</span>{' '}
    <Link component={GatsbyLink} to="/design-tokens/overview/">
      Check out the design tokens now
    </Link>
  </Inline>
);

export default Banner;
