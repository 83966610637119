import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {breakpoint} from '@nib/layout';
import {SkipLink, Target} from '@nib-components/skip-link';
import {Footer, Layout, Main, Sidebar} from '@nib/gatsby-theme-mesh-docs';
import Theme, {nib} from '@nib-components/theme';

import Header from '../../../components/header/Header';
import {BREAKPOINTS} from '../../../constants';
import Banner from '../../../components/banner';

const GridLayout = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: 3.5rem 1fr auto;
  grid-template-columns: 1fr;

  ${breakpoint(BREAKPOINTS.DRAWER)`
    grid-template-areas:
      "header header"
      "sidebar main"
      "sidebar footer";
    grid-template-columns: 15rem 1fr;
  `};
`;

const Content = props => {
  return (
    <Theme theme={nib}>
      <Layout bannerContent={<Banner />}>
        <SkipLink link="#content" text="Skip to content" />
        <GridLayout>
          <Header />
          <Sidebar location={props.location} />
          <Target id="content" component={Main} pageContext={props.pageContext} location={props.location}>
            {props.children}
          </Target>
          <Footer />
        </GridLayout>
      </Layout>
    </Theme>
  );
};

Content.displayName = 'Content';
Content.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object
};

export default Content;
